.CardStyle {
  padding: 10px;
  min-width: 100% !important;
}

.imageView {
  // margin: 0.25rem;
  width: auto;
  max-height: 23rem;
  max-width: 100%;
  object-fit: cover;
}
